<template>
  <div class="my-6">
    <CatSection :pet="this.$route.params.pet" />
    <NavMenuSecondary page="newpet" :data="this.$route.params.pet" />
  </div>
</template>

<script>
import CatSection from "../components/petrecord/cat/CatSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  components: {
    CatSection,
    NavMenuSecondary,
  },
  
};
</script>